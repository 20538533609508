import ProtectedAPIRepository from './ProtectedAPIRepository'
import APIResponseModel from './APIResponseModel'
import Result from './Result'
import InvoiceSectionRM from '../responsemodel/InvoiceSectionRM'
import InvoiceSectionDataMapper from '../datamapper/InvoiceSectionDataMapper'
import InvoiceSection from '../../models/InvoiceSection'

export default class InvoiceSectionRepository extends ProtectedAPIRepository {
  private dataMapper = new InvoiceSectionDataMapper()

  getData = async (ro?: GetInvoiceSectionRequestObject) => {
    const response = await this.post<APIResponseModel<InvoiceSectionRM>>('/billing/invoice', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<InvoiceSectionRM> = response.data
      if (data.data !== null) {
        const items = data.data.items.map((it) => this.dataMapper.map(it))
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: items,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  // getSingleInvoice = async (ro:)

  updateData = async (ro: UpdateInvoiceSectionRM) => {
    const formData = this.constructFormData(ro)
    const response = await this.patch<APIResponseModel<InvoiceSectionRM>>('/billing/invoice', formData)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<InvoiceSectionRM> = response.data
      if (data.data !== null) {
        const receivedData = this.dataMapper.map(data.data.items[0])
        return new Result.Success(receivedData)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  uploadInvoicePendencySheet = async (file: File) => {
    const formData = this.constructFormData({ sheet: file })
    const response = await this.patch<APIResponseModel<number[]>>('/billing/invoice/uploadTemplate', formData)

    if (response instanceof Result.Success) {
      const data: APIResponseModel<number[]> = response.data
      if (data.data !== null) {
        let receivedData = data.data.items
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: receivedData,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }

  uploadInvoiceReceipts = async (files: File[]) => {
    const formData = this.constructFormData({ sheet: files })
    // const response = await this.patch<APIResponseModel<InvoiceSectionRM>>('/billing/invoice/uploadReceipts', formData)
    const response = await this.patch<APIResponseModel<string[]>>('/billing/invoice/uploadReceipts', formData)

    if (response instanceof Result.Success) {
      // const data: APIResponseModel<InvoiceSectionRM> = response.data
      const data: APIResponseModel<string[]> = response.data
      if (data.data !== null) {
        // let receivedData: InvoiceSection[] = [];
        // for(let i=0; i< data.data.items.length; i++){
        //     let it= data.data.items[i];
        //     receivedData = receivedData.concat(this.dataMapper.map(it))
        // }

        let receivedData = data.data.items
     
        return new Result.Success({
          totalItemCount: data.data.total_item_count,
          items: receivedData,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
}

export interface GetInvoiceSectionRequestObject {
  id?: string
  case_id?: string
  claim_number?: string
  client_id?: string
  client_name?: string
  case_type_id?: string
  case_type_title?: string
  invoice_number?: string

  invoicePending?: boolean
  docReceived?: boolean
  invoiceUploadPending?: boolean

  from?: string
  to?: string
  from_invoice_date?: string
  to_invoice_date?: string
  search?: string
  limit?: number
  offset?: number
  reinvestigation?: boolean
  pushback?: boolean
  generatedCase?:boolean
  case_state?: string; 
}

export interface UpdateInvoiceSectionRM {
  claim_number: string
  case_type_title: string
  invoice_receipt?: File
  invoice: {
    invoice_number: string
    invoice_date: string
    professional_fee: number
    approved_expense: number
    document_expense: number
    other_expense: number
    incentive: number
    deduction: number
    gst: number
  
    
  }
}
