import React from "react";
import { Container, Grid, GridColumn } from "semantic-ui-react";
import { Title, Description } from "../../elements/Text";

interface Props {
  action?: React.ReactNode;
  title?: string;
  description?: string;
  children?: React.ReactNode;
}

const Page = (props: Props) => {
  return (
    <Container style={{ marginTop: "16px", marginBottom: "16px" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom:"10px" }}>
        {props.title ? <Title>{props.title}</Title> : null}
        {props.action && <div>{props.action}</div>} {/* Render the action */}
      </div>
      {props.description ? (
        <Description>{props.description}</Description>
      ) : null}
      {props.children}
    </Container>
  );
};

export default Page;
