import React, { JSXElementConstructor, ReactElement, ReactNodeArray, ReactPortal } from "react";
import { Segment, List, DropdownItemProps, Image, Grid, Divider, Header, Icon, Button, Form, Accordion, Input, Modal, Table, Dropdown, DropdownProps } from "semantic-ui-react";
import UserRepository from "../../../../../common/repository/UserRepository";
import Result from "../../../../../common/repository/Result";
import User from "../../../../../models/User";
import InvestigatorProfileCard from "../InvestigatorProfileCard";
import CaseRepository from "../../../../../common/repository/CaseRepository";
import Case from "../../../../../models/Case";
import { toast } from "../../../../common/Toast";
import constants from "../../../../../common/constants";
import RequirePermission from "../../../../base/RequirePermission";
import permissions from "../../../../../common/permissions";
import { TagsInput } from "react-tag-input-component";
import config from "../../../../../common/repository/config";
// import Role from "../../../../../models/Role";
// import CallPage from "./CallingPage";
// import axios from "axios";
import LocalStorage from "../../../../../lib/LocalStorage";
import FilesPopup from "../../../case/CaseViewPage/FilesPopup";
import ReactDOM from "react-dom";
import URLResource from "../../../../../models/URLResource";
import States from "../../../../../models/States";
import StateDistrictRepository from "../../../../../common/repository/StatesDistrictsRepository";
const investigationSpecifications = ["Cashless", "Detailed Investigation", "Insured Part", "Hospital Part", "Vicinity Check", "Document Verification",];

interface ActionAllocationProps {
  model: Case;
  updateModel(c: Case): void;
}

interface ActionAllocationState {
  roles: { id: string; title: string }[];
  role: string;
  inputValue: string;
  keypadVisible: boolean;
  modalOpen: boolean;
  rolesInCase: boolean;
  selectedRoleInCase: string | null;
  apiResponseModalOpen: any,
  apiResponseData: any,
  callItemModalOpen: boolean;
  callItemNumber: string;
  name: string;
  userRole: string;
  invoiceReceiptsVisible: boolean;
  stakeholder_role: { key: string, text: string, value: string }[]
  options: { key: string; text: string; value: string }[];
  contactNumber: string;
  handleConfirmCall: string;
  isCalling: boolean;
  isMinimized: boolean;
  confirmModalOpen: boolean;
  selectedContactNumber: string | null;
  active: boolean;
  disable_invoive_btn: boolean;
  loading: boolean;
  loadingInvestigators: boolean;
  investigatorOptions: DropdownItemProps[];
  investigators: User[];
  alloc_selectedInvestigator: string; //id
  alloc_selectedInvestigationSpecifications: string[];
  alloc_otherInvestigationSpecifications: string;
  alloc_selection: { investigator: User; specs: string[]; serviceType: string[], required_doc: string[] }[];
  servicesType: any[]
  services: string[]
  invoiceUrl: URLResource[];
  required_documents: string[]
  loadingStates: boolean;
  selected_state: string;
  districtOptions: DropdownItemProps[];
  selected_district: string;
  stateOptions: DropdownItemProps[];
  loadingDistrict: boolean;
  states: States[];
  district: string[];
  x: number;
  y: number;
  contact: {
    [x: string]: string | number | boolean | {} | ReactElement<any, string | JSXElementConstructor<any>> | ReactNodeArray | ReactPortal | null | undefined; name: string; role: string; contactNumber: string
  }[];
}
class ActionAllocation extends React.Component<
  ActionAllocationProps,
  ActionAllocationState
> {
  private usersRepo = new UserRepository();
  private caseRepo = new CaseRepository();
  private stateDistrictRepo = new StateDistrictRepository();

  constructor(props: ActionAllocationProps) {
    super(props);
    this.state = {
      options: [],
      inputValue: '',
      disable_invoive_btn: true,
      keypadVisible: false,
      rolesInCase: false,
      selectedRoleInCase: null,
      callItemNumber: "",
      apiResponseModalOpen: false,
      callItemModalOpen: false,
      apiResponseData: null,
      isCalling: false,
      isMinimized: false,
      active: false,
      loading: false,
      stakeholder_role: [],
      loadingInvestigators: false,
      investigatorOptions: [],
      investigators: [],
      alloc_selectedInvestigator: "",
      alloc_selectedInvestigationSpecifications: [],
      alloc_otherInvestigationSpecifications: "",
      alloc_selection: [],
      servicesType: props.model.getType().getServices(),
      services: props.model.getType().getServices(),
      required_documents: props.model.getType().getRequiredDocumne(),
      modalOpen: false,
      name: "",
      role: "",
      userRole: "",
      roles: [],
      contactNumber: "",
      contact: [],
      confirmModalOpen: false,
      selectedContactNumber: null,
      handleConfirmCall: "",
      invoiceReceiptsVisible: false,
      invoiceUrl: [],
      x: 0,
      y: 0,
      loadingStates: false,
      selected_state:"",
      districtOptions: [],
      selected_district:"",
      stateOptions: [],
      loadingDistrict:false,
      district: [],
      states: [],

    };
  }
  componentDidMount() {
    this.getInvestigators();
    this.getState();
    this.getUser();
    this.fetchContacts();
    this.handleConfirmCall();
    this.fetchStakeholderRoles();
    this.fetchInvoiceDocument(this.props.model.getId());
  }

  private fetchStakeholderRoles = async () => {
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) {
        console.error('Authentication token is missing.');
        return;
      }

      const response = await fetch(`${config.apiBaseURL}/stakeHolderRole/listRole`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });

      if (response.ok) {
        const result = await response.json();

        if (Array.isArray(result.data.items)) {
          const roles = result.data.items;
          console.log(roles, "kjhgfghj");
          this.setState({
            stakeholder_role: roles.map((role: any) => ({
              key: role.name,
              text: role.name,
              value: role.name
            }))
          });
        } else {
          console.error('Failed to fetch user by token');
        }
      } else {
        throw new Error('Failed to fetch roles');
      }
    } catch (error) {
      console.error('An error occurred while fetching roles');
    }
  };
  async fetchInvoiceDocument(case_id: string) {
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
    if (!token) {
      console.error("Authentication token is missing.");
      return;
    }
    try {
      const response = await fetch(`${config.apiBaseURL}/case/listInvoiceQc/${case_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": token,
        },
      });
      const data = await response.json();

      if (data.success && data.data) {
        const { items, message } = data.data;
        if (Array.isArray(items) && items.length > 0) {
          const flattenedItems = items.flat();
          this.setState((prevState) => ({
            ...prevState,
            invoiceUrl: flattenedItems,  // Set the flattened file URLs
            responseMessage: null,
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            responseMessage: message || "No invoice URL found.",
          }));
          toast.error(message || "No invoice URL found.");
        }
        this.setState({
          disable_invoive_btn: true,
        });
      } else {
        this.setState({
          disable_invoive_btn: true,
        });
      }
    } catch (error) {
      console.error("Error fetching invoice QC list:", error);
      toast.error("Error occurred while fetching data");
    }
  }
  private getUser = async () => {
    let result = await this.usersRepo.getUserByToken();
    if (result instanceof Result.Success) {
      this.setState({ userRole: result.data.getRole().getTitle() });
    }
  }

  private getState = () => {
    this.setState({ loadingStates: true }, async () => {
      let result = await this.stateDistrictRepo.getStates();
      if (result instanceof Result.Success) {
        const fetchedStates = result.data.items;
        const options = fetchedStates.map((it: any) => {
          return this.stateToDropDownOption(it);
        });

        this.setState({
          loadingStates: false,
          stateOptions: options.sort((a: any, b: any) => {
            if (a.text < b.text) return -1;
            if (a.text > b.text) return 1;
            return 0;
          }),
          states: fetchedStates
        });
      } else {
        this.setState({ loadingStates: false }, () => {
          const message = "Could not load States";
          toast.error(message);
        });
      }
    });
  };

  private getDistrict = (stateName: string) => {
    this.setState({ loadingDistrict: true }, async () => {
      let result = await this.stateDistrictRepo.getDistricts({ state: stateName });
      if (result instanceof Result.Success) {
        const fetchedDistricts = result.data.items;

        const options = fetchedDistricts.map((it: string) => {
          return this.districtToDropDownOption(it);
        });

        this.setState({
          loadingDistrict: false,
          districtOptions: options.sort((a: any, b: any) => {
            if (a.text < b.text) return -1;
            if (a.text > b.text) return 1;
            return 0;
          }),
          district: fetchedDistricts
        });

      } else {
        this.setState({ loadingDistrict: false }, () => {
          const message = "Could not load Districts";
          toast.error(message);
        });
      }
    });
  };

  private getInvestigators = (state?: string, district?: string) => {
    this.setState({ loadingInvestigators: true }, async () => {
      let result: any;
      if (state && district !== undefined) {
        result = await this.usersRepo.getUsersByRoleTitle({
          role: constants.roleTitle.investigator,
          location: {
            state: state,
            district: district,
          }
          // is_active: true,
        });
      } else {
        result = await this.usersRepo.getUsersByRoleTitle({
          role: constants.roleTitle.investigator
          // is_active: true,
        });
      }

      if (result instanceof Result.Success) {
        const investigators = result.data.items;
        const options = investigators.map((it: any) => {
          return this.investigatorToDropDownOption(it);
        });
        console.log(options,"oppppppp")
        this.setState({
          loadingInvestigators: false,
          investigatorOptions: options,
          investigators,
        });
      } else {
        this.setState({ loadingInvestigators: false }, () => {
          const message = result.message || "Could not load Investigators";
          toast.error(message);
        });
      }
    });
  };
  private allocateInvesitgators = () => {
    this.setState({ loading: true }, async () => {
      const { model, updateModel } = this.props;
      const allocations = this.state.alloc_selection.map((it) => {
        return {
          investigator_id: it.investigator.getId(),
          specifications: it.specs,
          services: it.serviceType,
          required_documents: it.required_doc
        };
      });
      const result = await this.caseRepo.allocateInvestigators({
       
        case_id: model.getId(),
        allocations,
      });
      console.log(Result,"oijmgyhbghnhnhnbhn")
      if (result instanceof Result.Success) {
        const c = result.data;
        this.setState(
          {
            loading: false,
            investigatorOptions: [],
            investigators: [],
            alloc_selectedInvestigator: "",
            selected_state: "",
            selected_district: "",
            alloc_selectedInvestigationSpecifications: [],
            alloc_otherInvestigationSpecifications: "",
            alloc_selection: [],
          },
          () => {
            updateModel(c);
            toast.success("Case Allocated Successfully");
            this.getInvestigators();
          }
        );
      } else {
        const message = result.message || "Something went wrong";
        this.setState({ loading: false }, () => {
          toast.error(message);
        });
      }
    });
  };
  handleService = (e: any) => {
    const { value, checked } = e.target
    if (checked) {
      this.setState({ services: [...this.state.services, value] })
    } else {
      this.setState({ services: this.state.services.filter((e) => e !== value) })
    }
  }
  handleCheckboxChange = (event: any) => {
    let newArray = [...this.state.services, event.target.id];
    if (this.state.services.includes(event.target.id)) {
      newArray = newArray.filter(day => day !== event.target.id);
    }
    this.setState({
      services: newArray
    });
  };
  handleOpenConfirmModal = (contactNumber: string) => {
    this.setState({ confirmModalOpen: true, selectedContactNumber: contactNumber });
  };
  handleCloseConfirmModal = () => {
    this.setState({ confirmModalOpen: false, selectedContactNumber: null });
  };

  handleEndCall = () => {
    this.setState({ isCalling: false, confirmModalOpen: false });
  };
  toggleMinimizeModal = () => {
    this.setState((prevState) => ({
      isMinimized: !prevState.isMinimized,
    }));
  };

  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  };
  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };
  handleAddContact = async () => {
    const { name, role, contactNumber } = this.state;

    const { model } = this.props;
    if (!name) {
      toast.error("Enter Stackholder Name");
      return;
    }
    const contactNumberPattern = /^[0-9]{10}$/;
    if (!contactNumberPattern.test(contactNumber)) {
      toast.error("Contact Number must be exactly 10 digits.");
      return;
    }

    const payload = {
      name: name,
      roleInCase: role,
      caseId: model.getId(),
      contactNumber: contactNumber,
    };
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) {
        console.error(token, 'Authentication token is missing.');
        return;
      }
      const response = await fetch(`${config.apiBaseURL}/case/addContact`, {

        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'x-access-token': token,
        },
        body: JSON.stringify(payload),
      });
      console.log("Response Status:", response.status);
      console.log("Response URL:", response.url);
      console.log(model.getId(), "__+=P::")

      if (response.ok) {
        const data = await response.json();
        toast.success("Contact added successfully");
        console.log("Contact added successfully", data);
        await this.fetchContacts();
        this.setState({ modalOpen: false });

      } else {
        console.error("Failed to add contact, status:", response.status);
      }
    } catch (error) {
      console.error("Error adding contact:", error);

    }
  };
  private fetchContacts = async () => {
    const { model } = this.props;
    try {
      const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
      if (!token) {
        console.error('Authentication token is missing.');
        return;
      }
      const caseId = model.getId();
      if (!caseId) {
        console.error('Case ID is missing.');
        return;
      }
      const response = await fetch(`${config.apiBaseURL}/case/listContact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'x-access-token': token,
        },
        body: JSON.stringify({ caseId }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log("API Response Data:", data);
        if (
          data.success &&
          data.data &&
          Array.isArray(data.data.items) &&
          data.data.items.length > 0
        ) {

          const contactArray = data.data.items[0]?.contact || [];
          this.setState({ contact: contactArray });
        } else {
          console.error("Unexpected data format:", data);

        }
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch contacts:", errorData.message);

      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
      toast.error("Error fetching contacts");
    }
  };
  handleContactNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, '');
    if (numericValue.length <= 10) {
      this.setState({ contactNumber: numericValue });
    }
  };
  // Conform Call API
  handleConfirmCall = async () => {
    const { selectedContactNumber } = this.state;
    const { model } = this.props;

    if (selectedContactNumber) {
      console.log(`Calling ${selectedContactNumber}`);
      this.setState({ isCalling: true });

      try {
        const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
        if (!token) {
          console.error('Authentication token is missing.');
          this.setState({ isCalling: false });
          return;
        }

        const caseId = model.getId();
        if (!caseId) {
          console.error('Case ID is missing.');
          this.setState({ isCalling: false });
          return;
        }
        const response = await fetch(`${config.apiBaseURL}/case/makeCall`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'x-access-token': token,
          },
          body: JSON.stringify({
            caseId,
            contactNumber: selectedContactNumber,
          }),
        });
        if (response.ok) {
          const data = await response.json();
          console.log("API Response:", data);
          this.setState({
            apiResponseModalOpen: true,
            apiResponseData: data.data.items,

          });
          this.setState({ apiResponseModalOpen: true }, () => {
            this.setState({ callItemModalOpen: false });
          });

        } else {
          const errorData = await response.json();
          console.error("Failed to initiate call:", errorData.message);
        }
      } catch (error) {
        console.error("Error initiating call:", error);
      } finally {
     
        this.setState({ isCalling: false });
        this.handleCloseConfirmModal();
      }
    }
  };

  private handleDropdownChange = (e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const value = data.value as string;
    console.log(value, "Selected role"); 
    this.setState({ role: value });
  };

  handleKeyPress = (value: string) => {
    this.setState((prevState) => ({
      inputValue: prevState.inputValue + value,
    }));
  };

  handleClear = () => {
    this.setState({ inputValue: '' });
  };

  handleSubmit = () => {

    this.setState({ inputValue: '', keypadVisible: false });
  };

  toggleKeypadVisibility = () => {
    this.setState((prevState) => ({
      keypadVisible: !prevState.keypadVisible,
    }));
  };
  render() {
    const { isCalling, isMinimized, apiResponseModalOpen, apiResponseData, callItemModalOpen, callItemNumber, roles, role, options, inputValue, keypadVisible } = this.state;
    if (this.state.isCalling) {
      return (
        <div>
          <Modal
            open={callItemModalOpen}
            onClose={() => this.setState({ callItemModalOpen: false })}
            size="small"
          >
            <Modal.Actions>
              <Button negative onClick={() => this.setState({ callItemModalOpen: false })}>
                Cancel
              </Button>
              <Button positive
              //  onClick={this.handleCallItemNumberSubmit}
              >
                Submit
              </Button>
            </Modal.Actions>
          </Modal>
          {isCalling && (
            <div
              style={{
                position: 'fixed',
                bottom: isMinimized ? 0 : '2%',
                right: '10%',
                zIndex: 1000,
                width: isMinimized ? '500px' : '400px',
              }}
            >
              <Segment raised>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {isMinimized ? (
                    <Icon
                      name="window restore"
                      onClick={this.toggleMinimizeModal}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <>
                      <span>Calling Contact {this.state.selectedContactNumber} </span>
                      <Icon
                        name="window minimize"
                        onClick={this.toggleMinimizeModal}
                        style={{ cursor: 'pointer' }}
                      />
                    </>
                  )}
                </div>
                {!isMinimized && (
                  <>
                    <Segment >
                      <Icon name="phone" loading />
                      Calling contact...{this.state.selectedContactNumber}
                    </Segment>
                    <Button
                      basic
                      color="red"
                      onClick={this.handleEndCall}
                      style={{ marginTop: '10px' }}
                    >
                      End Call
                    </Button>
                  </>
                )}
              </Segment>
            </div>
          )}
        </div>
      );
    }
        const selectionList = this.state.alloc_selection.map((it) => {
          return (
            <InvestigatorProfileCard
              showHeader
              model={it.investigator}
              investigationSpecifications={it.specs}
              required_doc={it.required_doc}
              serviceType={it.serviceType}
              onRemove={this.onRemove}
            />
          );
        });
        const investigationSpecsOptions = investigationSpecifications.map((it) => {
          return { text: it, value: it, key: it };
        });
        const caseState = this.props.model.getState();


    return (
      <>
        {this.props.model.getState() === "open" && (

          <Segment>
            <Header as="h4">Added Contacts</Header>
            <Table celled>
              <Table.Header>
                <Table.Row >
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Role In Case</Table.HeaderCell>
                  <Table.HeaderCell>Contact Number</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.contact.length ? (
                  this.state.contact
                    .slice()
                    .reverse()
                    .map((contact, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{contact.name}</Table.Cell>
                        <Table.Cell>{contact.role_in_case}</Table.Cell>
                        <Table.Cell>
                          <a
                            href="#"
                            style={{ color: "#0000FF" }}
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleOpenConfirmModal(contact.contact_number as string);
                            }}
                          >
                            {contact.contact_number}
                          </a>
                        </Table.Cell>
                      </Table.Row>
                    ))
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan="3" style={{ textAlign: "center" }}>
                      No contacts added yet.
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <Modal
              open={apiResponseModalOpen}
              onClose={() => this.setState({ apiResponseModalOpen: false })}
              size="small"
            >
              {/* This is Modal of Item of key  */}
              <Modal.Header>Please Make Sure To Remember This Pin For Calling</Modal.Header>
              <Modal.Content>
                <p style={{ fontSize: "50px", textAlign: "center" }}>{apiResponseData}</p>  {/* Display API response data */}
              </Modal.Content>

              <Modal
                open={callItemModalOpen}
                onClose={() => this.setState({ callItemModalOpen: false })}
                size="small"
              >

                <Modal.Actions>
                  <Button negative onClick={() => this.setState({ callItemModalOpen: false })}>
                    Cancel
                  </Button>
                  <Button positive
                  // onClick={this.handleCallItemNumberSubmit}
                  >
                    Submit
                  </Button>
                </Modal.Actions>
              </Modal>
              <Modal.Actions>
                <Button
                  positive
                  onClick={() => {
                    this.setState({ apiResponseModalOpen: false }, () => {
                      this.setState({ callItemModalOpen: true });
                    });
                  }}
                >
                  OK
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.state.confirmModalOpen}
              onClose={this.handleCloseConfirmModal}
              size="small"
            >
              <Modal.Header>Confirm Call</Modal.Header>
              <Modal.Content>
                <p>Are you sure you want to call {this.state.selectedContactNumber}?</p>
              </Modal.Content>
              <Modal.Actions>
                <Button negative onClick={this.handleCloseConfirmModal}>
                  Cancel
                </Button>
                <Button positive
                  onClick={this.handleConfirmCall}
                >
                  OK
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal.Content>

            </Modal.Content>

          </Segment>
        )}
        <Modal open={this.state.modalOpen} onClose={this.handleCloseModal} size="small">
          <Modal open={this.state.confirmModalOpen}
            onClose={this.handleCloseConfirmModal}
            size="small"
          >
          </Modal>
          <Modal.Header>Add Contact</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>Name</label>
                <Input
                  placeholder="StackHolder Name"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </Form.Field>
              <Form.Field style={{ color: 'red' }}>
                <label>Role</label>
                <Dropdown
                  placeholder="Select Role"
                  fluid
                  search
                  selection
                  options={this.state.stakeholder_role}
                  value={role}
                  onChange={this.handleDropdownChange}
                />
              </Form.Field>

              <Form.Field>
                <label>Contact Number</label>
                <Input
                  placeholder="Contact Number"
                  maxLength={10}
                  pattern="[0-9]*"
                  value={this.state.contactNumber}
                  onChange={this.handleContactNumberChange}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.handleCloseModal}>
              Cancel
            </Button>
            <Button positive onClick={this.handleAddContact}>
              Add
            </Button>
          </Modal.Actions>
        </Modal>
        <Accordion>
          <Accordion.Title active={this.state.active}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <Button
                  primary
                  disabled={
                    caseState !== constants.caseStates.generated &&
                    caseState !== constants.caseStates.open &&
                    caseState !== constants.caseStates.reinvestigate
                  }
                  onClick={() => this.setState({ active: !this.state.active })}
                  style={{ marginRight: "10px" }}
                >
                  <Icon name="dropdown" />
                  Allocate Investigator
                </Button>
                {this.props.model.getState() === "open" && (
                  <Button
                    onClick={this.handleOpenModal}
                    style={{ padding: ".78571429em 1.5em", backgroundColor: "green", color: "white" }}
                  >
                    Add Contact
                  </Button>
                )}
              </div>
              <div>
                {
                    // (this.state.userRole === constants.roleTitle.oh || this.state.userRole === constants.roleTitle.ceo || this.state.userRole === constants.roleTitle.md) && (
                      <Button
                      disabled={this.state.invoiceUrl.length === 0}
                      style={{ textAlign: "right" }}
                      onClick={(e) =>
                        this.setState({
                          x: e.clientX,
                          y: e.clientY,
                          invoiceReceiptsVisible: true,
                        })
                      }
                      primary
                    >
                      {this.state.invoiceUrl.length === 0 ? "No Invoice Receipts" : "View Invoice Receipts"}
                    </Button>
                  // )
                }
               
              </div>
            </div>
            {this.state.invoiceReceiptsVisible &&
          (document.getElementById("root") ? (
            ReactDOM.createPortal(
              <FilesPopup
                x={this.state.x}
                y={this.state.y}
                onClose={() => this.setState({ invoiceReceiptsVisible: false })}
                title="Invoice Receipts"
                files={this.state.invoiceUrl}
                allocationId={this.props.model.getId()}
              />,
              document.getElementById("root") as HTMLElement
            )
          ) : (
            <div>Root element not found!</div>
          ))}

          </Accordion.Title>
          <Accordion.Content active={this.state.active}>
            <Segment loading={this.state.loading}>
              <Grid columns={2}>
                <Divider vertical>
                  <Icon name="arrow alternate circle right" />
                </Divider>
                <Grid.Row>
                  {/* investigator selection */}
                  <Grid.Column>
                    <Header as="h4">
                      Step 1
                      <Header.Subheader>
                        Select state and district in which you want to have an investigator,
                        you will see available investigator on that location below.
                        Selecting an investigator on the basis of state and district is optional if you want you can directly choose an investigator.
                      </Header.Subheader>
                    </Header>
                    <Form>
                      <Form.Dropdown
                        search
                        selection
                        label="Select State"
                        loading={this.state.loadingStates}
                        value={this.state.selected_state}
                        onChange={(_, { value, }) => {
                          this.setState({ selected_state: value as string })
                          this.getDistrict(value as string)
                        }}
                        options={this.state.stateOptions}
                        placeholder="Select State"
                      />
                      <Form.Dropdown
                        search
                        selection
                        label="Select District"
                        loading={this.state.loadingDistrict}
                        value={this.state.selected_district}
                        onChange={(_, { value, }) => {
                          this.setState({
                            selected_district: value as string,
                          })
                          this.getInvestigators(this.state.selected_state, value as string)
                        }
                        }
                        options={this.state.districtOptions}
                        placeholder="Select District"
                      />

                      <Form.Dropdown
                        search
                        selection
                        label="Select Invesitgator"
                        loading={this.state.loadingInvestigators}
                        value={this.state.alloc_selectedInvestigator}
                        onChange={(_, { value, }) =>
                          this.setState({
                            alloc_selectedInvestigator: value as string,
                          })

                        }
                        options={this.state.investigatorOptions}
                        placeholder="Select Investigator"
                      />

                      <Button
                        primary
                        icon
                        labelPosition="right"
                        onClick={() => {
                          this.setState({
                            alloc_selectedInvestigator: "",
                            selected_district: "",
                            selected_state: "",
                            districtOptions: [],

                          })
                          this.getInvestigators();
                        }
                        }
                        disabled={
                          this.state.alloc_selectedInvestigator.length === 0 &&
                          this.state.selected_district.length === 0 &&
                          this.state.selected_state.length === 0
                        }
                        style={{ marginBottom: "10px", width: "120px" }}
                      >
                        Clear
                        <Icon name="undo" />
                      </Button>
                      {/* <Form.Dropdown
                        fluid
                        selection
                        multiple
                        label="Investigation Specifications"
                        placeholder="Select Multiple"
                        options={investigationSpecsOptions}
                        value={
                          this.state.alloc_selectedInvestigationSpecifications
                        }
                        onChange={(_, { value }) =>
                          this.setState({
                            alloc_selectedInvestigationSpecifications:
                              value as string[],
                          })
                        }
                      /> */}
                      <label><strong> Services</strong></label>
                      <Grid>
                        <Grid.Row>
                          {this.state.servicesType.map((item, index) => <>
                            <Grid.Column width={8}>
                              <input
                                className="CaseTypeCheck"
                                key={index}
                                type="checkbox"
                                id={item}
                                value={item}
                                checked={this.state.services.includes(item)}
                                onChange={this.handleCheckboxChange}
                              />
                              <label htmlFor={item}>{item}</label>
                            </Grid.Column>
                          </>
                          )}
                        </Grid.Row>
                      </Grid>
                      <label><strong> Required Documents</strong></label>
                      <TagsInput
                        value={this.state.required_documents}
                        // onChange={(value) => this.setState({ required_documents: value })}
                        name="Required Documents"
                        placeHolder="PanCard"
                        disabled
                      />
                      {/* <Form.Input
                        label="Other Specifications"
                        placeholder="e.g. Accidental, Health"
                        value={this.state.alloc_otherInvestigationSpecifications}
                        onChange={(_, { value }) =>
                          this.setState({
                            alloc_otherInvestigationSpecifications: value,
                          })
                        }
                      /> */}
                      <Button
                        primary
                        icon
                        labelPosition="right"
                        onClick={this.onAdd}
                        disabled={
                          this.state.alloc_selectedInvestigator.length === 0 ||
                          this.state.services.length === 0 || this.state.required_documents.length === 0
                        }
                      >
                        Add
                        <Icon name="arrow alternate circle right" />
                      </Button>
                    </Form>
                  </Grid.Column>
                  {/* investigator selection */}

                  {/* allocation  */}
                  <Grid.Column>
                    <Header as="h4">
                      Step 2
                      <Header.Subheader>
                        Click on the Allocate Button to start Investigation.
                        Investigators will receive an email with Case Detail.
                      </Header.Subheader>
                    </Header>

                    <Header as="h5">
                      Selected Investigators: {this.state.alloc_selection.length}
                    </Header>
                    <List>{selectionList}</List>
                    <Button
                      icon
                      positive
                      disabled={this.state.alloc_selection.length === 0}
                      labelPosition="right"
                      onClick={this.allocateInvesitgators}
                    >
                      Allocate Investigator
                      {this.state.alloc_selection.length > 1 ? "s" : ""}
                      <Icon name="check circle" />
                    </Button>
                  </Grid.Column>
                  {/* allocation  */}
                </Grid.Row>
              </Grid>
            </Segment>
          </Accordion.Content>
        </Accordion>
      </>
    );
  }
  private onAdd = () => {
    const id = this.state.alloc_selectedInvestigator;
    const investigator = this.findInvestigatorById(id);
    if (investigator === null) return;
    //remove investigator from options
    this.removeInvestigatorFromOptions(id);
    //get specs
    const specs = this.getSpecs();
    const serviceType = this.state.services
    const required_doc = this.state.required_documents
    //add investigator to selection list
    this.addInvestigatorInSelectionList(investigator, specs, serviceType, required_doc);
    //clear the current selection
    this.setState({
      alloc_selectedInvestigator: "",
      alloc_selectedInvestigationSpecifications: [],
      alloc_otherInvestigationSpecifications: "",
      selected_district: "",
      selected_state: ""

      // services: [],
      // required_documents: [],
    });
  };
  private removeInvestigatorFromOptions = (id: string) => {
    const options = this.state.investigatorOptions;
    const index = options.findIndex((it) => it.value === id);
    if (index === -1) return;
    options.splice(index, 1);
    this.setState({ investigatorOptions: [...options] });
  };
  private addInvestigatorInSelectionList = (
    investigator: User,
    specs: string[],
    serviceType: string[],
    required_doc: string[]
  ) => {
    const selection = this.state.alloc_selection;
    selection.push({ investigator, specs, serviceType, required_doc });
    this.setState({ alloc_selection: [...selection] });
  };
  private findInvestigatorById = (id: string) => {
    const index = this.state.investigators.findIndex((it) => it.getId() === id);
    if (index === -1) return null;
    return this.state.investigators[index];
  };
  private getSpecs = () => {
    const investigationSpecs = this.state.alloc_selectedInvestigationSpecifications;
    const services = this.state.services
    const required_doc = this.state.required_documents
    const otherSpecs = this.state.alloc_otherInvestigationSpecifications;

    let totalSpecs = [...investigationSpecs];
    if (this.state.alloc_otherInvestigationSpecifications.trim().length > 0) {
      totalSpecs = totalSpecs.concat(otherSpecs.split(","));
    }
    return totalSpecs;
  };
  private onRemove = (investigator: User) => {
    //remove investigator from selection list
    this.removeInvestigatorFromSelectionList(investigator);

    //add investigator back to list
    this.addInvestigatorInOptions(investigator);
  };
  private removeInvestigatorFromSelectionList = (investigator: User) => {
    const selection = this.state.alloc_selection;
    const index = selection.findIndex(
      (it) => it.investigator.getId() === investigator.getId()
    );
    if (index === -1) return;

    selection.splice(index, 1);
    this.setState({ alloc_selection: [...selection] });
  };
  private addInvestigatorInOptions = (investigator: User) => {
    const options = this.state.investigatorOptions;
    options.push(this.investigatorToDropDownOption(investigator));
    this.setState({ investigatorOptions: [...options] });
  };
  renderInvestigatorDropdownLabel = (model: User) => {
    // console.log(model,"userrrrrrr")
    const districts = User.getDistricts(model.getPrimaryLocations()).join();
    let colorCoding = "";
    let textColor = "";
    let fontSize = ""; 
    // #21ba45 rgb(50, 170, 90)
    if (model.getLevel()) {
        console.log(model.getLevel(), "Level");
        colorCoding = model.getLevel() === "primary" ? "#21ba45" :
                          model.getLevel() === "secondary" ? "rgb(255, 232, 0)" :
                          model.getLevel() === "tertiary" ? "rgb(215, 8, 0)" : "";
      if (colorCoding === "#21ba45" || colorCoding === "rgb(215, 8, 0)") {
        textColor = "#FFFFFF";
      }
      fontSize = colorCoding === "#21ba45" ? "16px" :       
      colorCoding === "rgb(255, 232, 0)" ? "16px" :        
      colorCoding === "rgb(215, 8, 0)" ? "16px" : fontSize;
    }
    return (
      <div style={{ display: "flex", backgroundColor:`${colorCoding}`, color: textColor, gap: "10px", padding:"8px 0px 8px 10px"}}>
        <Image src={model.getProfilePicURL()} avatar />
        <div style={{ margin: "0 4px" }}>
          <div style={{fontSize: fontSize}}>{model.getName()}</div>
          <div style={{ fontSize: 12 }}>{districts}</div>
        </div>
      </div>
    );
  };

  
  private investigatorToDropDownOption = (investigator: User) => {
    console.log(investigator,"invvvvvv")
    // const style = investigator.style || "";
    return {
      key: investigator.getId(),
      value: investigator.getId(),
      text: investigator.getName(),
      content: this.renderInvestigatorDropdownLabel(investigator),
    };
  };

  private renderStateDropDownLabel = (model: States) => {
    return (
      <div style={{ display: "flex", }}>
        <div style={{ margin: "0 4px" }}>
          <div>{model.getName()}</div>
        </div>
      </div>
    );
  }

  private stateToDropDownOption = (state: States) => {
    return {
      key: state.getId(),
      value: state.getName(),
      text: state.getName(),
      content: this.renderStateDropDownLabel(state)
    }
  }

  private renderDistrictDropDownLabel = (model: string) => {
    return (
      <div style={{ display: "flex", }}>
        <div style={{ margin: "0 4px" }}>
          <div>{model}</div>
        </div>
      </div>
    );
  }

  private districtToDropDownOption = (district: string) => {
    return {
      key: district,
      value: district,
      text: district,
      content: this.renderDistrictDropDownLabel(district)
    }
  }
}



export default (props: any) => {
  return (
    <RequirePermission permission={permissions.Case.allocate}>
      <ActionAllocation {...props} />
    </RequirePermission>
  );
};
