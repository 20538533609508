import DataMapper from './DataMapper'
import CaseRM from '../responsemodel/CaseRM'
import Case from '../../models/Case'
import CaseTypeDataMapper from './CaseTypeDataMapper'
import HospitalDataMapper from './HospitalDataMapper'
import PolicyTypeDataMapper from './PolicyTypeDataMapper'
import PolicyType from '../../models/PolicyType'
import Hospital from '../../models/Hospital'
import CaseCategoryDataMapper from './CaseCategoryDataMapper'
import LocationDataMapper from './LocationDataMapper'
import CaseFactory from '../../factories/CaseFactory'
import Location from '../../models/Location'
import URLResource from '../../models/URLResource'
import RemarkDataMapper from './RemarkDataMapper'
import ReminderDataMapper from './ReminderDataMapper'
import ClientDataMapper from './ClientDataMapper'
import Client from '../../models/Client'

export default class CaseDataMapper extends DataMapper<CaseRM, Case> {
  private caseCategoryDataMapper = new CaseCategoryDataMapper()
  private caseTypeDataMapper = new CaseTypeDataMapper()
  private locationDataMapper = new LocationDataMapper()
  private clientDataMapper = new ClientDataMapper()
  private hospitalDataMapper = new HospitalDataMapper()
  private policyTypeDataMapper = new PolicyTypeDataMapper()
  private remarkDataMapper = new RemarkDataMapper()
  private reminderDataMapper = new ReminderDataMapper()

  map = (rm: CaseRM) => {
    const caseType = this.caseTypeDataMapper.map(rm.case_type)
    const caseCategory = this.caseCategoryDataMapper.map(rm.case_category)

    let tpa: Client | null = null
    if (rm.tpa) tpa = this.clientDataMapper.map(rm.tpa)

    let company: Client | null = null
    if (rm.insurance_company !== null) company = this.clientDataMapper.map(rm.insurance_company)

    let insuredLocation: Location | null = null
    if (rm.insured_location !== null) insuredLocation = this.locationDataMapper.map(rm.insured_location)

    let hospital: Hospital | null = null
    if (rm.hospital !== null) hospital = this.hospitalDataMapper.map(rm.hospital)

    let policyType: PolicyType | null = null
    if (rm.policy) policyType = this.policyTypeDataMapper.map(rm.policy)

    let remarks = rm.remarks.map((it) => this.remarkDataMapper.map(it))
    let reminders = rm.remainders.map((it) => this.reminderDataMapper.map(it))

    let documents: URLResource[] = []
    if (rm.documents !== null) documents = rm.documents.map((it) => new URLResource(it))

    const repudiationEvidence = rm.repudiation_evidences.map((it) => new URLResource(it))

    let report = null
    if (rm.outcome_report !== null) report = new URLResource(rm.outcome_report)

    let finalReport = null
    if (rm.final_report !== null) finalReport = new URLResource(rm.final_report)

    let query_trigger_document = null
    if (rm.query_trigger_document !== null) query_trigger_document = new URLResource(rm.query_trigger_document)

    return CaseFactory.create({
      id: rm.id,
      caseCategory: caseCategory,
      caseType: caseType,
      inwardDateTime: rm.inward_date_time,
      claimNumber: rm.claim_number,
      TPA: tpa,
      insuranceCompany: company,
      insuranceCompanyName: rm.insurance_company_name,
      subjectLine: rm.subject_line,
      insuredName: rm.insured_name,
      insuredLocation: insuredLocation,
      insuredContact: rm.insured_phone,
      insuredAddress: rm.insured_address,
      insuredAadhaarNumber: rm.insured_aadhaar_card,
      hospital: hospital,
      policyType: policyType,
      policyInception: rm.policy_inception,
      policyNumber: rm.policy_number,
      availableTAT: rm.available_TAT,
      remainingTAT: rm.remaining_TAT,
      triggers: rm.triggers,
      remarks: remarks,
      reminders: reminders,
      documents: documents,
      repudiationEvidence: repudiationEvidence,
      isVerifiedByDoctor: rm.doctor_verification_status,
      isVerifiedByPathologist: rm.pathologist_verification_status,
      report: report,
      finalReport: finalReport,
      finalRemarks: rm.final_remarks,
      outcome: rm.outcome,
      state: rm.state,
      tatTiming: rm.tatTiming,
      reinvestigation: rm.reinvestigation,
      repudiation_ground: rm.repudiation_ground,
      pushback: rm.pushback,
      query_trigger_document:query_trigger_document,
      isWithdrawed: rm.is_withdrawn,
      closedOn: rm.closed_on,
      majorObservation: rm.major_observations
    })
  }
}
