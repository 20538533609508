import Case from './Case'
import Location from './Location'
import CaseCategory from './CaseCategory'
import CaseType from './CaseType'
import Hospital from './Hospital'
import PolicyType from './PolicyType'
import URLResource from './URLResource'
import Remark from './Remark'
import Reminder from './Reminder'
import Client from './Client'

export default class CompleteCase extends Case {
  constructor(
    id: string,
    type: CaseType,
    category: CaseCategory,
    insuredLocation: Location | null,
    inwardDateTime: string,
    claimNumber: string,
    TPA: Client | null,
    insuranceCompany: Client | null,
    insuranceCompanyName: string | null,
    availableTAT: number,
    remainingTAT: number,
    subjectLine: string,
    state: string,
    tatTiming: any,
    reinvestigation: boolean,
    repudiation_ground: string,
    pushback: boolean,
    query_trigger_document:URLResource|null,
    private insuredName: string,
    private insuredContact: string | null,
    private insuredAddress: string | null,
    private insuredAadhaarNumber: string | null,
    private hospital: Hospital,
    private policyType: PolicyType,
    private policyInception: string,
    private policyNumber: string,
    private triggers: string,
    private remarks: Remark[],
    private reminders: Reminder[],
    private documents: URLResource[],
    private repudiationEvidence: URLResource[],
    private isVerifiedByDoctor: boolean,
    private isVerifiedByPathologist: boolean,
    private report: URLResource | null,
    private finalReport: URLResource | null,
    private finalRemarks: string | null,
    private outcome: string | null,
    private isWithdrawed: boolean,
    private closedOn: string | null,
    private majorObservation: string
  ) {
    super(id, type, category, insuredLocation, inwardDateTime, claimNumber, TPA, insuranceCompany, insuranceCompanyName, availableTAT, remainingTAT, subjectLine, state, tatTiming, reinvestigation, repudiation_ground, pushback,query_trigger_document)
  }

  getInsuredName = () => this.insuredName
  getInsuredContact = () => this.insuredContact
  getInsuredAddress = () => this.insuredAddress
  getInsuredAadhaarNumber = () => this.insuredAadhaarNumber
  getHospital = () => this.hospital
  getPolicyType = () => this.policyType
  getPolicyInception = () => this.policyInception
  getPolicyNumber = () => this.policyNumber
  getTriggers = () => this.triggers
  getRemarks = () => this.remarks
  getReminders = () => this.reminders
  getDocuments = () => this.documents
  getRepudiationEvidence = () => this.repudiationEvidence
  getIsVerifiedByDoctor = () => this.isVerifiedByDoctor
  getIsVerifiedByPathologist = () => this.isVerifiedByPathologist
  getReport = () => this.report
  getFinalReport = () => this.finalReport
  getFinalRemarks = () => this.finalRemarks
  getOutcome = () => this.outcome
  getIsWithdrawed = () => this.isWithdrawed
  getClosedOn = () => this.closedOn
  getMajorObservations = () => this.majorObservation

}
