import DomainModel from './DomainModel'
import User from './User'

export default class UserAttendance extends DomainModel {
  constructor(
    private id: any,
    private user_id: any,
    private name: string,
    private emp_id: number,
    private designation: string,
    private department: string,
    private state: string,
    private location: string,
    private year: number,
    private monthly_attendance: {},
    private created_on: Date,
    private modified_on: Date,
  ) {
    super()
  }

  getId = () => this.id
  getUser = () => this.user_id
  getName = () => this.name
  getEmpId = () => this.emp_id
  getDesignation = () => this.designation
  getDepartment = () => this.department
  getState = () => this.state
  getLocation = () => this.location
  getYear = () => this.year
  getMonthlyAttandence = () => this.monthly_attendance
  getCreatedOn = () => this.created_on
  getModifiedOn = () => this.modified_on
}