import axios, { AxiosInstance, CancelToken } from 'axios'

export interface NetworkManagerConfig {
  baseURL?: string
  headers?: {}
}

/**
 * Raw Repository is responsible for fetching data
 * from network and returning in raw form
 *
 * Exceptions must be handled by the children
 */
export default class RawRepository {
  protected networkManager: AxiosInstance

  constructor(config: NetworkManagerConfig) {
    this.networkManager = axios.create(config)
  }

  public createSource = () => axios.CancelToken.source()

  protected rawGet = async <T>(path: string, queryParams?: {}, token?: CancelToken) => {
    let config = {}
    if (queryParams) config = { params: queryParams, cancelToken: token }
    const response = await this.networkManager.get<T>(path, config)
    return response.data
  }

  protected rawPut = async <T>(path: string, body?: {}, token?: CancelToken) => {
    const response = await this.networkManager.put<T>(path, body, {
      cancelToken: token,
    })
    return response.data
  }

  protected rawPost = async <T>(path: string, body?: {}, token?: CancelToken) => {
    const response = await this.networkManager.post<T>(path, body)
    return response.data
  }

  protected rawPatch = async <T>(path: string, body?: {}, token?: CancelToken) => {
    const response = await this.networkManager.patch<T>(path, body, {
      cancelToken: token,
    })
    return response.data
  }

  protected rawDelete = async <T>(path: string, queryParams?: {}, token?: CancelToken) => {
    let config = {}
    if (queryParams) config = { params: queryParams, cancelToken: token }
    const response = await this.networkManager.delete<T>(path, config)
    return response.data
  }

  protected constructFormData = (data: any) => {
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      if (data[key] === undefined || data[key] === null) {
        return
      } else if (Array.isArray(data[key]) && data[key].length > 0 && data[key][0] instanceof File) {
        data[key].forEach((file: File) => formData.append(key, file))
      } else if (data[key] instanceof File) {
        formData.append(key, data[key])
      } else if (typeof data[key] === 'object') {
        formData.append(key, JSON.stringify(data[key]))
      } else if (typeof data[key] === 'boolean' || typeof data[key] === 'number') {
        formData.append(key, `${data[key]}`)
      } else if (typeof data[key] === 'string') {
        formData.append(key, data[key])
      }
    })
    return formData
  }
}