import DataMapper from './DataMapper'
import UserAttendanceRM from '../responsemodel/UserAttendanceRM'
import UserAttendance from '../../models/UserAttendance'
import UserDataMapper from './UserDataMapper'

export default class UserAttendanceDataMapper extends DataMapper<UserAttendanceRM, UserAttendance> {
  private userDataMapper = new UserDataMapper()

  map = (rm: UserAttendanceRM) => {
    return new UserAttendance(
      rm.id,
      rm.user_id,
      rm.name,
      rm.emp_id,
      rm.designation,
      rm.department,
      rm.state,
      rm.location,
      rm.year,
      rm.monthly_attendance,
      rm.created_on,
      rm.modified_on
    )
  }
}