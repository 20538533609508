import Role from './Role'
import DomainModel from './DomainModel'
import constants from '../common/constants'
import Location from './Location'

export default class User extends DomainModel {
  constructor(
    private id: string,
    private employeeId: string,
    private name: string,
    private email: string,
    private otherEmail: string | null,
    private phone: string,
    private otherPhone: string | null,
    private joiningDate: string,
    private role: Role,
    private locations: Location[][],
    private address: string | null,
    private dob: string | null,
    private profilePicURL: string | null,
    private parent: string | null,
    private active: boolean,
    private clients: [],
    private case_categories: [],
    private investigator_location: any,
    private level?: string,
    private desig_update_on?:string,
    // private style?: string
  ) {
    super()
  }

  getId = () => this.id
  getEmployeeId = () => this.employeeId
  getName = () => this.name
  getEmail = () => this.email
  getOtherEmail = () => this.otherEmail
  getPhone = () => this.phone
  getOtherPhone = () => this.otherPhone
  getJoiningDate = () => this.joiningDate
  getRole = () => this.role
  getLocations = () => this.locations
  getAddress = () => this.address
  getDOB = () => this.dob
  getDegiUpdate=()=>this.desig_update_on
  getProfilePicURL = () => {
    if (this.profilePicURL === null) return constants.defaultProfileAvatarURL
    else return this.profilePicURL
  }
  getParent = () => this.parent
  getActive = () => this.active
  getClients = () => this.clients
  getCaseCategories = () => this.case_categories
  getInvestigatorLocation = () => this.investigator_location.coordinates
  getLevel = () => this.level
  // getStyle = () => this.style
  hasPermission = (permission: string) => this.role.hasPermission(permission)

  hasAnyPermission = (permissions: string[]) => this.role.hasPermission(permissions, true)

  hasAllPermissions = (permissions: string[]) => this.role.hasPermission(permissions)

  getPrimaryLocations = () => this.locations[0]
  getSecondaryLocations = () => (this.locations.length >= 2 ? this.locations[1] : [])
  getTertiaryLocations = () => (this.locations.length >= 3 ? this.locations[2] : [])

  static getDistricts = (locations: Location[]) => locations.map((it) => it.getDistrict())

  static isLocationIncluded = (locations: Location[], location: { state: string; district: string }) => {
    const loc = locations.find((it) => it.getState() === location.state && it.getDistrict() === location.district)
    if (loc) return true
    else return false
  }
}
