import React, { useContext } from 'react'
import User from '../../../../models/User'
import Role from '../../../../models/Role'
import UserRepository, { UpdateUserRequestObject, AddUserRequestObject } from '../../../../common/repository/UserRepository'
import { Form, Checkbox, Divider, Dropdown, Header, Input, Message, Button, Modal, Icon } from 'semantic-ui-react'
import Result from '../../../../common/repository/Result'
import RoleRepository from '../../../../common/repository/RoleRespository'
import { toast } from '../../../common/Toast'
import StateDistrictRepository from '../../../../common/repository/StateDistrictRepository'
import './../../../../extensions/date'
import UserProfileCard from '../../../common/UserProfileCard'
import UserLocationInput, { UserLocationInputValue } from './UserLocationInput'
import Location from '../../../../models/Location'
import FormLabel from '../../../common/FormLabel'
import DateTimePicker from '../../../app/DateTimePicker'
import constants from '../../../../common/constants'
import validator from '../../../../common/validator'
import UserFormPageContext from './UserFormPageContext'
import { useHistory } from 'react-router-dom'
import ClientRepository from '../../../../common/repository/ClientRepository'
import CaseCategoryRepository from '../../../../common/repository/CaseCategoryRepository'
import Client from "../../../../models/Client"
import CaseCategory from "../../../../models/CaseCategory"
import Autocomplete from "react-google-autocomplete";
import LocationMap from "./LocationMap"
interface Props {
    model?: User
    history: { push(path: string): void }
    onSuccess(user: User): void
}

interface State {
    form_loading: boolean
    form_roles: Role[]
    form_reportee: string[]
    form_loadingRoles: boolean
    form_locations: Location[]
    form_loadingLocations: boolean
    form_selectedRole: Role | null
    form_selectedProfilePicFile: File | null
    form_loadingParentUsers: boolean
    form_parentUsers: User[]

    user_name: string
    user_employeeId: string
    user_email: string
    user_otherEmail: string
    user_phone: string
    user_otherPhone: string
    user_role: string
    user_parent: string
    user_primaryLocations: UserLocationInputValue[]
    user_secondaryLocations: UserLocationInputValue[]
    user_tertiaryLocations: UserLocationInputValue[]
    user_joiningDate: string
    user_profilePicURL: string | null
    user_dob: string
    user_address: string
    user_password: string
    user_active: boolean

    loadingEssentialData: boolean
    clientOptions: Client[]
    ClientValue: []
    caseCategoryOption: CaseCategory[]
    caseCategoryValue: []
    coordinates: any
    modalOpen: boolean
    // investigator_location: any
}

class UserForm extends React.Component<Props, State> {
    private userRepo = new UserRepository()
    private roleRepo = new RoleRepository()
    private stateRepo = new StateDistrictRepository()
    private clientRepo = new ClientRepository()
    private CaseCategoryRepository = new CaseCategoryRepository()

    constructor(props: Props) {
        super(props)
        const userStateFromModel = this.deriveUserStateFromModel(this.props.model)
        let form_selectedRole: Role | null = null
        if (this.props.model) form_selectedRole = this.props.model.getRole()
        this.state = {
            form_loading: false,
            form_roles: [],
            form_reportee: [],
            form_loadingRoles: false,
            form_loadingLocations: false,
            form_locations: [],
            form_selectedRole,
            form_loadingParentUsers: false,
            form_parentUsers: [],
            ...userStateFromModel,
            user_password: '',
            form_selectedProfilePicFile: null,

            loadingEssentialData: false,
            clientOptions: [],
            // ClientValue: [],
            caseCategoryOption: [],
            caseCategoryValue: [],
            // coordinates: [],
            modalOpen: false
        }
    }

    componentDidMount() {
        this.getClient()
        this.getCaseCategory()
        this.getRoles()
        this.getLocations()
        if (this.props.model)
            this.getParentUsers()
    }


    componentDidUpdate(prevProps: Props) {
        if (prevProps.model !== this.props.model) {
            const stateFromModel = this.deriveUserStateFromModel(this.props.model)
            let role = null
            if (this.props.model)
                role = this.props.model.getRole()
            this.setState({ ...stateFromModel, form_selectedRole: role }, this.getParentUsers)
        }
    }

    private isUpdate = () => this.props.model instanceof User


    getLocations = () => {
        this.setState({ form_loadingLocations: true }, async () => {
            const result = await this.stateRepo.getAll()
            if (result instanceof Result.Success) {
                this.setState({ form_loadingLocations: false, form_locations: result.data.items })
            } else {
                this.setState({ form_loadingLocations: false })
            }
        })
    }


    /** fetch parent users based on selected role */
    getParentUsers = () => {
        const role = this.state.form_selectedRole!
        this.setState({ form_loadingParentUsers: true }, async () => {
            const result = await this.userRepo.getUsersByRoleLevel(role.getLevel() - 1)
            if (result instanceof Result.Success) {
                const users = result.data.items
                this.setState({ form_loadingParentUsers: false, form_parentUsers: users })
            } else {
                this.setState({ form_loadingParentUsers: false }, () => {
                    const message = result.message || 'Could not load Users'
                    toast.error(message)
                })
            }
        })
    }



    private deriveUserStateFromModel = (model?: User) => {
        let active = true
        if (model)
            active = model.getActive()

        return {
            user_name: (model && model.getName()) || '',
            user_employeeId: (model && model.getEmployeeId()) || '',
            user_email: (model && model.getEmail()) || '',
            user_otherEmail: (model && model.getOtherEmail()) || '',
            user_phone: (model && model.getPhone()) || '',
            user_otherPhone: (model && model.getOtherPhone()) || '',
            user_role: (model && model.getRole().getId()) || '',
            user_parent: (model && model.getParent()) || '',
            user_primaryLocations: (model && model.getPrimaryLocations().map(it => ({ state: it.getState(), district: it.getDistrict() }))) || [],
            user_secondaryLocations: (model && model.getSecondaryLocations().map(it => ({ state: it.getState(), district: it.getDistrict() }))) || [],
            user_tertiaryLocations: (model && model.getTertiaryLocations().map(it => ({ state: it.getState(), district: it.getDistrict() }))) || [],
            user_joiningDate: (model && model.getJoiningDate()) || '',
            user_profilePicURL: (model && model.getProfilePicURL()) || '',
            user_dob: (model && model.getDOB()) || '',
            user_address: (model && model.getAddress()) || '',
            user_active: active,
            ClientValue: (model && model.getClients() || []),
            caseCategoryValue: (model && model.getCaseCategories() || []),
            coordinates: (model && model.getInvestigatorLocation() || [])
        }
    }


    private getRoles = () => {
        this.setState({ form_loadingRoles: true }, async () => {
            const result = await this.roleRepo.getRoles()
            if (result instanceof Result.Success) {
                const roles = result.data.items;
                this.setState({ form_loadingRoles: false, form_roles: roles })
            } else {
                this.setState({ form_loadingRoles: false }, () => {
                    const message = result.message || 'Couldn\'t load Roles'
                    toast.error(message)
                })
            }
        })
    }

    private getClient = () => {
        this.setState({ form_loadingRoles: true }, async () => {
            const result = await this.clientRepo.getClients()
            if (result instanceof Result.Success) {
                const roles = result.data.items
                this.setState({ form_loadingRoles: false, clientOptions: roles })
            } else {
                this.setState({ form_loadingRoles: false }, () => {
                    const message = result.message || 'Couldn\'t load Client'
                    toast.error(message)
                })
            }
        })
    }

    private getCaseCategory = () => {
        this.setState({ form_loadingRoles: true }, async () => {
            const result = await this.CaseCategoryRepository.getCaseCategory()
            if (result instanceof Result.Success) {
                const roles = result.data.items
                this.setState({ form_loadingRoles: false, caseCategoryOption: roles })
            } else {
                this.setState({ form_loadingRoles: false }, () => {
                    const message = result.message || 'Couldn\'t load Case Category'
                    toast.error(message)
                })
            }
        })
    }

    private onSubmit = () => {
        this.setState({ form_loading: true }, async () => {
            if (this.props.model) this.updateUser()
            else this.addUser()
        })
    }


    /**
     * update user
     */
    private updateUser = async () => {
        /** create request object */
        const ro: UpdateUserRequestObject = {
            id: this.props.model!.getId(),
            emp_id: this.state.user_employeeId,
            name: this.state.user_name,
            email: this.state.user_email,
            other_email: this.state.user_otherEmail,
            phone: this.state.user_phone,
            other_phone: this.state.user_otherPhone,
            joining_date: this.state.user_joiningDate,
            role: this.state.user_role,
            locations: [this.state.user_primaryLocations, this.state.user_secondaryLocations, this.state.user_tertiaryLocations],
            is_active: this.state.user_active
        }

        if (this.state.ClientValue.length !== 0) ro.clients = this.state.ClientValue
        if (this.state.caseCategoryValue.length !== 0) ro.case_categories = this.state.caseCategoryValue
        if (this.state.user_parent.trim().length !== 0)
            ro.parent = this.state.user_parent
        if (this.state.coordinates.length !== 0) ro.coordinates = this.state.coordinates

        if (this.state.form_selectedProfilePicFile !== null)
            ro.profile_pic = this.state.form_selectedProfilePicFile

        if (this.state.user_dob.trim().length !== 0)
            ro.dob = this.state.user_dob

        if (this.state.user_address.trim().length !== 0)
            ro.address = this.state.user_address

        if (this.state.user_password.trim().length !== 0)
            ro.password = this.state.user_password

        if (this.state.coordinates.length == 0 && this.state.form_selectedRole?.getTitle() == "Investigator") {
            this.setState({ form_loading: false })
            return toast.warn("Please choose your address location.")
        }
        const result = await this.userRepo.updateUser(ro)
        if (result instanceof Result.Success) {
            this.setState({ form_loading: false }, () => toast.success('User updated successfully'))
        } else {
            this.setState({ form_loading: false }, () => {
                const message = result.message || 'Something went wrong'
                toast.error(message)
            })
        }
    }
    /**
     * add user
     */
    private addUser = async () => {
        /** create request object */
        const ro: AddUserRequestObject = {
            emp_id: this.state.user_employeeId,
            name: this.state.user_name,
            email: this.state.user_email,
            other_email: this.state.user_otherEmail,
            phone: this.state.user_phone,
            other_phone: this.state.user_otherPhone,
            joining_date: this.state.user_joiningDate,
            role: this.state.user_role,
            locations: [this.state.user_primaryLocations, this.state.user_secondaryLocations, this.state.user_tertiaryLocations],
            password: this.state.user_password
        }
        if (this.state.ClientValue.length !== 0) ro.clients = this.state.ClientValue
        if (this.state.caseCategoryValue.length !== 0) ro.case_categories = this.state.caseCategoryValue
        if (this.state.coordinates.length !== 0) ro.coordinates = this.state.coordinates
        if (this.state.user_parent.trim().length !== 0) ro.parent = this.state.user_parent
        if (this.state.user_dob.trim().length !== 0) ro.dob = this.state.user_dob
        if (this.state.user_address.trim().length !== 0) ro.address = this.state.user_address
        if (this.state.form_selectedProfilePicFile !== null) ro.profile_pic = this.state.form_selectedProfilePicFile

        if (this.state.coordinates.length == 0 && this.state.form_selectedRole?.getTitle() == "Investigator") {
            this.setState({ form_loading: false })
            return toast.warn("Please choose your address location.")
        }
        const result = await this.userRepo.addUser(ro)
        if (result instanceof Result.Success) {
            const user = result.data
            this.setState({ form_loading: false }, () => {
                toast.success('User added successfully')
                this.props.onSuccess(user)
                this.props.history.push('/manage/users')
            })
        } else {
            this.setState({ form_loading: false }, () => {
                const message = result.message || 'Something went wrong'
                toast.error(message)
            })
        }
    }

    private addressFun = (data: any, coordinates: any) => {
        if (coordinates === null) {
            return toast.warn("Please coordinates address")
        }
        this.setState({ modalOpen: false, coordinates: [coordinates?.lat, coordinates?.lng] })
    }

    render() {
        const roleOptions = this.state.form_roles.map(it => ({ key: it.getId(), value: it.getId(), text: it.getTitle(), reportee: it.getReportee() }))
        const parentUsersOptions = this.state.form_parentUsers.map(it => ({ key: it.getId(), value: it.getId(), text: it.getName() }))
        const ClientOptions = this.state.clientOptions.map(it => ({ key: it.getId(), value: it.getId(), text: it.getName() }))
        const CategoryOptions = this.state.caseCategoryOption.map(it => ({ key: it.getId(), value: it.getId(), text: it.getTitle() }))

        const Locationkey = "AIzaSyC0y2S4-iE2rHkYdyAsglz_qirv0UtpF1s"


        let selectedRoleTitle: string | string[] = '';
        if (this.state.form_selectedRole !== null)
            selectedRoleTitle = this.state.form_selectedRole.getReportee().includes("State/District") ? this.state.form_selectedRole.getReportee() : this.state.form_selectedRole.getTitle();
        return (
            <Form>

                {this.props.model && <UserProfileCard model={this.props.model} />}

                {!this.props.model && (
                    <Message info compact>
                        <p>Fields marked with <span style={{ color: 'red' }}>*</span> are required.</p>
                    </Message>
                )}

                <Header as='h4' color='grey'>Hierarchy Information</Header>

                {/* role input */}
                <Form.Group inline>
                    <Form.Field required>
                        <FormLabel width='120px'>Select Role</FormLabel>
                        <Dropdown
                            placeholder='Select Role'
                            value={this.state.user_role}
                            options={roleOptions}
                            onChange={(_, { value }) => {
                                const role = this.state.form_roles.find(it => it.getId() === value as string)
                                if (role === undefined)
                                    return
                                // const reportee = this.state.form_roles.find(it => it.getId === )
                                this.setState(
                                    { user_role: value as string, form_selectedRole: role, user_parent: '' },
                                    () => this.getParentUsers()
                                )
                            }}
                            selection />
                    </Form.Field>
                </Form.Group>
                {/* role input */}

                {/* parent input */}
                {
                    <>
                        <Form.Group inline>
                            {
                                this.state.form_selectedRole?.getReportee().includes("Designation") &&
                                <Form.Field>
                                    <FormLabel width='120px'>Select Head</FormLabel>
                                    <Dropdown
                                        selection
                                        search
                                        placeholder='Select Head'
                                        loading={this.state.form_loadingParentUsers}
                                        value={this.state.user_parent}
                                        onChange={(_, { value }) => this.setState({ user_parent: value as string })}
                                        options={parentUsersOptions} 
                                    />
                                </Form.Field>
                            }

                        </Form.Group>

                        {this.state.form_selectedRole?.getReportee().includes("Client") && this.state.form_selectedRole?.getReportee().includes("CaseCategory") ?
                            <>
                                <Form.Group inline>
                                    <Form.Field>
                                        <FormLabel width='120px'>Select Client</FormLabel>
                                        <Dropdown
                                            selection
                                            multiple
                                            search
                                            label="Investigation Specifications"
                                            placeholder="Select Multiple"
                                            options={ClientOptions}
                                            value={this.state.ClientValue}
                                            onChange={(_, { value }) => this.setState({ ClientValue: value as [], })
                                            }
                                        />
                                    </Form.Field>
                                </Form.Group>
                                
                                <Form.Group inline>
                                    <Form.Field>
                                        <FormLabel width='120px'>Select Case Category</FormLabel>
                                        <Dropdown
                                            selection
                                            multiple
                                            search
                                            label="Case Category"
                                            placeholder="Select Multiple"
                                            options={CategoryOptions}
                                            value={this.state.caseCategoryValue}
                                            onChange={(_, { value }) => this.setState({ caseCategoryValue: value as [], })
                                            }
                                        />
                                    </Form.Field>
                                </Form.Group>
                               
                            </>
                            :
                            this.state.form_selectedRole?.getReportee().includes("Client") ?
                            <Form.Group inline>
                                <Form.Field>
                                    <FormLabel width='120px'>Select Client</FormLabel>
                                    <Dropdown
                                        selection
                                        multiple
                                        search
                                        label="Investigation Specifications"
                                        placeholder="Select Multiple"
                                        options={ClientOptions}
                                        value={this.state.ClientValue}
                                        onChange={(_, { value }) => this.setState({ ClientValue: value as [], })
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>
                            : this.state.form_selectedRole?.getReportee().includes("CaseCategory") ? 
                            <Form.Group inline>
                                <Form.Field>
                                    <FormLabel width='120px'>Select Case Category</FormLabel>
                                    <Dropdown
                                        selection
                                        multiple
                                        search
                                        label="Case Category"
                                        placeholder="Select Multiple"
                                        options={CategoryOptions}
                                        value={this.state.caseCategoryValue}
                                        onChange={(_, { value }) => this.setState({ caseCategoryValue: value as [], })
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>
                            :
                            <></>     
                        }
                    </>
                }
                {/* parent input */}

                <Divider />

                <Header as='h4' color='grey'>General Information</Header>
                {/* employee id input */}
                <Form.Group inline>
                    <Form.Field required>
                        <label style={{ width: 100 }}>Employee ID</label>
                        <Input
                            placeholder='101'
                            value={this.state.user_employeeId}
                            onChange={(_, { value }) => this.setState({ user_employeeId: value })} />
                    </Form.Field>
                </Form.Group>
                {/* employee id input */}

                {/* name input */}
                <Form.Group inline>
                    <Form.Field required>
                        <label style={{ width: 100 }}>Name</label>
                        <Input
                            placeholder='Ramlal Sharma'
                            value={this.state.user_name}
                            onChange={(_, { value }) => this.setState({ user_name: value })} />
                    </Form.Field>
                </Form.Group>
                {/* name input */}


                {/* phone input */}
                <Form.Group inline>
                    <Form.Field required>
                        <label style={{ width: 100 }}>Phone</label>
                        <Input
                            placeholder='Primary'
                            value={this.state.user_phone}
                            onChange={(_, { value }) => {
                                if (validator.phone.isValid(value))
                                    this.setState({ user_phone: value })
                            }} />
                    </Form.Field>
                    <Input
                        placeholder='Secondary (optional)'
                        value={this.state.user_otherPhone}
                        onChange={(_, { value }) => {
                            if (validator.phone.isValid(value))
                                this.setState({ user_otherPhone: value })
                        }} />
                </Form.Group>
                {/* phone input */}


                {/* email input */}
                <Form.Group inline>
                    <Form.Field required>
                        <label style={{ width: 100 }}>Email</label>
                        <Input
                            placeholder='Primary'
                            value={this.state.user_email}
                            onChange={(_, { value }) => this.setState({ user_email: value })} />
                    </Form.Field>
                    <Input
                        placeholder='Secondary (optional)'
                        value={this.state.user_otherEmail}
                        onChange={(_, { value }) => this.setState({ user_otherEmail: value })} />
                </Form.Group>
                {/* email input */}


                {/* joining date */}
                <Form.Group inline>
                    <Form.Field required>
                        <FormLabel width='100px'>Joining Date</FormLabel>
                        <Input>
                            <DateTimePicker
                                type='date'
                                placeholder='Pick Joining Date'
                                value={this.state.user_joiningDate}
                                onChange={value => this.setState({ user_joiningDate: value })} />
                        </Input>
                    </Form.Field>
                </Form.Group>
                {/* joining date */}


                {/* locations input */}
                <UserLocationInput
                    roleTitle={selectedRoleTitle}
                    locations={this.state.form_locations}
                    value={[this.state.user_primaryLocations, this.state.user_secondaryLocations, this.state.user_tertiaryLocations]}
                    onChange={value => this.setState({
                        user_primaryLocations: value[0],
                        user_secondaryLocations: value[1],
                        user_tertiaryLocations: value[2]
                    })} />
                {/* locations input */}


                <Divider />

                <Header as='h4' color='grey'>Personal Information</Header>

                {/* date of birth */}
                <Form.Group inline>
                    <Form.Field>
                        <FormLabel width='100px'>Date of Birth</FormLabel>
                        <Input>
                            <DateTimePicker
                                type='date'
                                placeholder='Pick DOB'
                                value={this.state.user_dob}
                                onChange={value => this.setState({ user_dob: value })} />
                        </Input>
                    </Form.Field>
                </Form.Group>
                {/* date of birth */}


                {/* home address */}
                <Form.Group inline>
                    <Form.Field required>
                        <label style={{ width: 100 }}>Home Address</label>
                        <Input
                            placeholder='Street Address/Landmark'
                            value={this.state.user_address}
                            onChange={(_, { value }) => this.setState({ user_address: value })}
                        // disabled={this.state.form_selectedRole?.getTitle() == "Investigator"}
                        />


                        {/* <Autocomplete
                            apiKey={Locationkey}
                            style={{ width: 310 }}
                            onPlaceSelected={(place: any) => {
                                this.setState({ user_address: place.formatted_address, coordinates: [place.geometry.location.lat(), place.geometry.location.lng()] })
                            }}
                            options={{
                                types: ["address"],
                                componentRestrictions: { country: "ind" },
                                // fields: ["address_components", "geometry"],
                            }}
                            defaultValue={this.state.user_address}
                        /> */}
                    </Form.Field>

                    {this.state.form_selectedRole?.getTitle() == "Investigator" && < Button onClick={() => this.setState({ modalOpen: true })} icon="map" />}
                    <Modal open={this.state.modalOpen} onClose={() => this.setState({ modalOpen: false })} closeIcon closeOnDimmerClick={false} closeOnEscape={false}>
                        <Modal.Header>Address Map</Modal.Header>
                        <div style={{ height: "500px", width: "500px" }} title="Google address location">
                            <LocationMap add={this.addressFun} cancel={() => this.setState({ modalOpen: false })} />
                        </div>
                    </Modal>
                </Form.Group>
                {this.state.user_address && <Message color='teal' compact style={{ marginTop: 0, marginLeft: 110 }}>
                    <p>{this.state.user_address}</p>
                    {this.state.form_selectedRole?.getTitle() == "Investigator" && <>
                        <h5><Button icon="location arrow"></Button>Lat: {this.state.coordinates[0]}</h5>
                        <h5><Button icon="compass outline"></Button>Lng:  {this.state.coordinates[1]}</h5>
                    </>}
                </Message>}
                {/* home address */}


                {/* profile pic url */}
                <Form.Group inline>
                    <Form.Field>
                        <label style={{ width: 100 }}>Profile Picture</label>
                        <Input
                            type='file'
                            accept='image/*'
                            onChange={(e) => {
                                if (e.target.files && e.target.files[0]) {
                                    const file = e.target.files[0]
                                    if (file instanceof File) {
                                        this.setState({ form_selectedProfilePicFile: file })
                                    } else {
                                        this.setState({ form_selectedProfilePicFile: null })
                                    }
                                }
                            }} />
                    </Form.Field>
                </Form.Group>
                {/* profile pic url */}
                <Divider />
                <Header as='h4' color='grey'>Password {(this.isUpdate() && 'and Status')}</Header>
                <Message info compact style={{ marginTop: 0 }}>
                    <p>Set password for the user which then user can use to login. An E-mail will be sent to the user <br /> with the credentials on User creation or updation automatically.</p>
                </Message>

                {/* password input */}
                <Form.Group inline>
                    <Form.Field required={!this.isUpdate()}>
                        <label style={{ width: 100 }}>Set Password</label>
                        <Input
                            value={this.state.user_password}
                            onChange={(_, { value }) => this.setState({ user_password: value })} />
                    </Form.Field>
                </Form.Group>
                {/* password input */}


                {/* user status */}
                {this.isUpdate() && (
                    <Form.Group inline>
                        <Form.Field>
                            <label style={{ width: 100 }}>Active</label>
                            <Checkbox
                                toggle
                                checked={this.state.user_active}
                                onChange={() => {
                                    this.setState(currentState => {
                                        return { user_active: !currentState.user_active }
                                    })
                                }} />
                        </Form.Field>
                    </Form.Group>
                )}
                {/* user status */}


                <Button
                    primary
                    size='large'
                    onClick={this.onSubmit}
                    disabled={
                        // this.state.user_address.trim().length === 0 ||
                        this.state.form_loading ||
                        this.state.user_role.trim().length === 0 ||
                        this.state.user_employeeId.trim().length === 0 ||
                        this.state.user_name.trim().length === 0 ||
                        this.state.user_phone.trim().length === 0 ||
                        this.state.user_email.trim().length === 0 ||
                        this.state.user_joiningDate.trim().length === 0 ||
                        // (
                        //     this.state.form_selectedRole?.getTitle() === constants.roleTitle.oh && (this.state.ClientValue.length === 0 || this.state.caseCategoryValue.length === 0)

                        // ) ||
                        (
                            this.state.form_selectedRole &&
                            this.state.form_selectedRole.getReportee().includes("Designation") &&
                            this.state.user_parent.trim().length === 0

                        ) ||
                        (
                            this.state.form_selectedRole &&
                            this.state.form_selectedRole.getReportee().includes("State/District") &&
                            this.state.user_primaryLocations.length === 0
                        ) ||
                        (
                            this.state.form_selectedRole &&
                            this.state.form_selectedRole.getTitle() === constants.roleTitle.investigator &&
                            (
                                this.state.user_primaryLocations.length === 0 ||
                                this.state.user_secondaryLocations.length === 0 ||
                                this.state.user_tertiaryLocations.length === 0
                            )
                        ) ||
                        (
                            !this.isUpdate() &&
                            this.state.user_password.trim().length === 0
                        )
                    }
                    loading={this.state.form_loading}
                    style={{ margin: '16px 0' }}>
                    Submit
                </Button>
            </Form>
        )
    }
}

export default () => {
    const ctx = useContext(UserFormPageContext)
    const history = useHistory()
    return <UserForm model={ctx.user || undefined} history={history} onSuccess={ctx.setUser} />
}