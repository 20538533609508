import permissions from "../../../../common/permissions";

export interface PermissionType {
  title: string
  permissions: { label: string; value: string }[]
}
export interface StakeholderType {
  key: string
  text: string
  value: string
}
export default {
  frontend: [
    {
      title: 'Case',
      permissions: [
        { label: 'Initiate Case', value: 'Case.initiate' },
        { label: 'Generate Case', value: 'Case.generate' },
        { label: 'Perform Case Allocation', value: 'Case.allocate' },
        { label: 'Perform Initial Closure', value: 'Case.fold' },
        { label: 'Perform QC', value: 'Case.check' },
        { label: 'Perform Pushback Operation', value: 'Case.pushback' },
        { label: 'Perform Final Closure', value: 'Case.close' },
        { label: 'Perform Grading', value: 'Case.grade' },
        { label: 'Withdraw Case', value: 'Case.withdraw' },
        { label: 'Reinvestigate Case', value: 'Case.reinvestigate' },
        { label: 'Remove Case', value: 'Case.remove' },
        { label: 'View Initiated', value: 'Case.View.initiated' },
        { label: 'View Generated', value: 'Case.View.generated' },
        { label: 'View Open', value: 'Case.View.open' },
        { label: 'View Pending QC', value: 'Case.View.fold' },
        { label: 'View QC', value: 'Case.View.checked' },
        { label: 'View Closed', value: 'Case.View.close' },
        { label: 'View Graded', value: 'Case.View.graded' },
        { label: 'Perform Trigger Updation', value: 'Case.Trigger.update' },
        { label: 'Perform Remark Updation', value: 'Case.Remark.update' },
        { label: 'Perform Reminder Updation', value: 'Case.Reminder.update' },
        {
          label: 'Remove Allocated Investigator',
          value: 'Case.Investigator.remove',
        },
      ],
    },
    {
      title: 'Manage & Master Section',
      permissions: [
        { label: 'Access Manage Section', value: 'Manage.CRUD' },
        { label: 'Access Master Section', value: 'Master.CRUD' },
      ],
    },
    {
      title: 'Settings',
      permissions: [{ label: 'View & Update Settings', value: 'Setting.update' }],
    },
    {
      title: 'User',
      permissions: [{ label: 'Login', value: 'User.Login' }],
    },
    {
      title: 'Billing',
      permissions: [{ label: 'Manage Billing', value: 'Billing.update' }],
    },
    {
      title: 'Evaluation',
      permissions: [{ label: 'Access Evaluation Section', value: 'Evaluation.find' }],
    },
    {
      title: 'Backup',
      permissions: [{ label: 'Create Backup', value: 'Backup' }],
    },
    {
      title: 'Hospital',
      permissions: [
        { label: 'Add Hospital ', value: 'Hospital.add' },
        { label: 'Update Hospital ', value: 'Hospital.update' },
        { label: 'Delete Hospital ', value: 'Hospital.delete' },
      ],
    },
    {
      title: 'User Role',
      permissions: [
        { label: 'Add User Role', value: 'Role.add' },
        { label: 'Update User Role', value: 'Role.update' },
        { label: 'Delete User Role', value: 'Role.delete' },
      ],
    }
  ],

  backend: [
    {
      title: 'Case',
      permissions: [
        { label: 'Case.initiate', value: 'Case.initiate' },
        { label: 'Case.generate', value: 'Case.generate' },
        { label: 'Case.allocate', value: 'Case.allocate' },
        { label: 'Case.fold', value: 'Case.fold' },
        { label: 'Case.check', value: 'Case.check' },
        { label: 'Case.pushback', value: 'Case.pushback' },
        { label: 'Case.close', value: 'Case.close' },
        { label: 'Case.grade', value: 'Case.grade' },
        { label: 'Case.withdraw', value: 'Case.withdraw' },
        { label: 'Case.remove', value: 'Case.remove' },
        { label: 'Case.View.initiated', value: 'Case.View.initiated' },
        { label: 'Case.View.generated', value: 'Case.View.generated' },
        { label: 'Case.View.open', value: 'Case.View.open' },
        { label: 'Case.View.fold', value: 'Case.View.fold' },
        { label: 'Case.View.checked', value: 'Case.View.checked' },
        { label: 'Case.View.close', value: 'Case.View.close' },
        { label: 'Case.View.graded', value: 'Case.View.graded' },
        { label: 'Case.Trigger.update', value: 'Case.Trigger.update' },
        { label: 'Case.Remark.update', value: 'Case.Remark.update' },
        { label: 'Case.Reminder.update', value: 'Case.Reminder.update' },
        {
          label: 'Case.Investigator.remove',
          value: 'Case.Investigator.remove',
        },
      ],
    },
    {
      title: 'Case Type',
      permissions: [
        { label: 'CaseType.add', value: 'CaseType.add' },
        { label: 'CaseType.update', value: 'CaseType.update' },
        { label: 'CaseType.delete', value: 'CaseType.delete' },
      ],
    },
    {
      title: 'Client',
      permissions: [
        { label: 'Client.add', value: 'Client.add' },
        { label: 'Client.update', value: 'Client.update' },
        { label: 'Client.delete', value: 'Client.delete' },
        { label: 'Client.disable', value: 'Client.disable' }
      ],
    },
    {
      title: 'Policy',
      permissions: [
        { label: 'Policy.add', value: 'Policy.add' },
        { label: 'Policy.update', value: 'Policy.update' },
        { label: 'Policy.delete', value: 'Policy.delete' },
      ],
    },
    {
      title: 'Hospital',
      permissions: [
        { label: 'Hospital.add', value: 'Hospital.add' },
        { label: 'Hospital.update', value: 'Hospital.update' },
        { label: 'Hospital.delete', value: 'Hospital.delete' },
      ],
    },
    {
      title: 'Settings',
      permissions: [{ label: 'View & Update Settings', value: 'Setting.update' }],
    },
    {
      title: 'User',
      permissions: [
        { label: 'User.add', value: 'User.add' },
        { label: 'User.update', value: 'User.update' },
        { label: 'User.delete', value: 'User.delete' },
      ],
    },
    {
      title: 'StakeHolder Role',
      permissions: [
        { label: 'Add Role ', value: 'StakeHolderRole.add' },
        { label: 'Update Role ', value: 'StakeHolderRole.update' },
        { label: 'Delete Role ', value: 'StakeHolderRole.delete' },
      ],
    },
    {
      title: 'User Role',
      permissions: [
        { label: 'Add User Role', value: 'Role.add' },
        { label: 'Update User Role', value: 'Role.update' },
        { label: 'Delete User Role', value: 'Role.delete' },
        { label: 'Reportee List', value: 'Reportee.list' },
      ],
    }
  ],
}
