import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Grid, Dropdown } from 'semantic-ui-react';
import Permissions from './Permissions';
import permissionlist from './permissionlist';
import LocalStorage from '../../../../lib/LocalStorage';
import config from '../../../../common/repository/config';
import Role from '../../../../models/Role';
import FormLabel from '../../../common/FormLabel';
import { toast } from '../../../common/Toast'
import UserRoleListing from './UserRoleListing';

type AddUserRoleProps = {
  roles: Role[];
  selectedRole: Role | null;
  onPermissionsChange: (permissions: {
    role: string;
    history: string;
    roles: Role[];
    level: number;
    permissions: string[];
    backendPermissions: string[];
  }) => void;
  onUpdatePermissions: (permissions: {
    role: string;
    level: number;
    permissions: string[];
    backendPermissions: string[];
  }) => void;
};

const AddUserRole: React.FC<AddUserRoleProps> = ({
  roles,
  selectedRole,
  onPermissionsChange,
  onUpdatePermissions,
}) => {
  const [roleName, setRoleName] = useState<string>('');
  const [roleId, setRoleId] = useState<string>('');
  const [reporteeOptions, setReporteeOptions] = useState<{ key: string; value: string; text: string }[]>([]);
  const [disableReportee,setDisableReportee] = useState(false);
  const [reportee, setReportee] = useState<string[] | null>(null);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [backendPermissions, setBackendPermissions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState<string | number | undefined>(undefined);
  const [stateDistrictSelected, setStateDistrictSelected] = useState(false);
  const [clientSelected, setClientSelected] = useState(false);
  const [caseCategorySelected, setCaseCategorySelected] = useState(false);
  const [designationSelected, setDesignationSelected] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [disableAll, setDisableAll] = useState(false);  // State to disable all values


  const roleOptions = roles && roles.length > 0 ? roles.map(role => ({
    key: role.getId(),
    value: role.getId(),
    text: role.getTitle(),
  })) : [];
  useEffect(() => {
    const fetchReporteeData = async () => {
      try {
        const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
        if (!token) {
          throw new Error('Authentication token is missing.');
        }
    
        const response = await fetch(`${config.apiBaseURL}/userRole/reportee`,
          {
            method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });
    
        const data = await response.json();
        if (response.ok) {
          const dropdownOptions = Object.entries(data.data.items).map(([key, value]) => ({
            key,
            value: key,
            text: String(value), 
          }));
    
          console.log('Reportee Options:', dropdownOptions);
          setReporteeOptions(dropdownOptions);
        } else {
          throw new Error(data.message || 'Failed to fetch reportee data.');
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred while fetching reportee data.');
      }
    };
    fetchReporteeData();
  }, []);

  const handleRoleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoleName(e.target.value);
  };
  const handleRoleIdChange = (e: any, { value }: any) => {
    console.log(setRoleId(value));
  };
  const handleReporteeChange = (e: any, { value }: any) => {
    const selectedValues = Array.isArray(value) ? value : [value];
    if (selectedValues.includes('Location') || selectedValues.includes('Desig')) {
     
      setReportee(selectedValues);
      setDisableReportee(true); 
    } else {
      const isClientOrCaseCategorySelected =
        selectedValues.includes('Client') || selectedValues.includes('CaseCategory');
  
      if (isClientOrCaseCategorySelected) {
        setDisableReportee(false); 
      }
      setReportee(selectedValues); 
    }
  
    setSelectedValue(value); 
  };
  
  
  
const handleSubmit = async (e: React.FormEvent) => {
  e.preventDefault();
  setLoading(true);
  setError(null);
  const reporteeArray = Array.isArray(reportee) ? reportee : [reportee];
  const role = roles.find(r => r.getId() === roleId);
  if (!role) {
    setError('Role not found');
    setLoading(false);
    return;
  }

  const currentLevel = role.getLevel();
  console.log('Role Object:', role);
  console.log('Current Level:', currentLevel);
  const level = typeof currentLevel === 'number' ? currentLevel + 1 : 1;

  const payload = {
    name: roleName,
    reportee: reporteeArray,
    permissions,
    backend_permissions: backendPermissions,
    roleId,
    level,
  };

  try {
    const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
    if (!token) {
      setError('Authentication token is missing.');
      setLoading(false);
      return;
    }

    const response = await fetch(`${config.apiBaseURL}/userRole`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
      body: JSON.stringify(payload),
    });

    const result = await response.json();
    console.log(result,"hhh")

    if (response.ok) {
      setRoleName('');
      setPermissions([]);
      setBackendPermissions([]);
      setRoleId('');
      setReportee(null);
      onUpdatePermissions({
        role: roleName,
        level: 0,
        permissions,
        backendPermissions,
      });
      toast.success("User Role Created Successfully");
    } else {
      setError(result.message || 'Failed to create user role');
    }
  } catch (error) {
    setError(error instanceof Error ? error.message : 'An error occurred while creating user role');
  } finally {
    setLoading(false);
  }
};
  function handleFrontendPermissionToggle(permission: string, operation: 'add' | 'remove'): void {
    setPermissions(prevPermissions => {
      const newPermissions = [...prevPermissions];
      if (operation === 'add' && !newPermissions.includes(permission)) {
        newPermissions.push(permission);
      } else if (operation === 'remove') {
        const index = newPermissions.indexOf(permission);
        if (index > -1) {
          newPermissions.splice(index, 1);
        }
      }
      return newPermissions;
    });
  }
  const handleBackendPermissionToggle = (permission: string, operation: 'add' | 'remove') => {
    setBackendPermissions(prevPermissions =>
      operation === 'add' ? [...prevPermissions, permission] : prevPermissions.filter(p => p !== permission)
    );
  };
  
  return (
    <>
      <h2 style={{ marginBottom: '20px' }}>Add User Form</h2>
      <Form onSubmit={handleSubmit}>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Field>
                <FormLabel width={''}>Role Name</FormLabel>
                <Input
                  type="text"
                  value={roleName}
                  onChange={handleRoleNameChange}
                  placeholder="Enter role name"
                  style={{ width: '100%' }}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <FormLabel width={''}>Select Head(For Herarchy Purpose Only)</FormLabel>
                <Dropdown
                  placeholder="Select a Role"
                  fluid
                  selection
                  options={roleOptions}
                  value={roleId}
                  onChange={handleRoleIdChange}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Field>
                <FormLabel width={''}>Supervisor</FormLabel>
                <Dropdown
                    placeholder="Select Supervisor"
                    fluid
                    multiple
                    selection
                    value={reportee || []}
                    options={reporteeOptions}
                    onChange={handleReporteeChange}
                    disabled={disableAll} 
                  />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
           {/* Permissions Section */}
      {roleName &&  (
        <>
          <h4 style={{ marginTop: "20px" }}>Permissions</h4>
          <Permissions
            list={permissionlist.frontend}
            permissions={permissions}
            onPermissionToggle={handleFrontendPermissionToggle} />
          <Permissions
            list={permissionlist.backend}
            permissions={backendPermissions}
            onPermissionToggle={handleBackendPermissionToggle} />
        </>
      )}
        <div style={{ marginTop: '20px' }}>
          <Button
            primary
            loading={loading}
            // disabled={!isFormValid || loading}
            type="submit"
            style={{ backgroundColor: '#21ba45', width: '150px' }}
          >
            Submit
          </Button>
        </div>
      </Form>
      <UserRoleListing></UserRoleListing>
    </>
  );
};

export default AddUserRole;
