import ProtectedAPIRepository from './ProtectedAPIRepository'
import RoleDataMapper from '../datamapper/RoleDataMapper'
import APIResponseModel from './APIResponseModel'
import RoleRM from '../responsemodel/RoleRM'
import Result from './Result'

export default class RoleRepository extends ProtectedAPIRepository {
  private dataMapper = new RoleDataMapper()

  getRoles = async (ro?: GetRolesRequestObject) => {
    if (ro?.level_greater_than) if (ro.level_greater_than > 19) ro.level_greater_than = 0
    const response = await this.post<APIResponseModel<RoleRM>>('userRole', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<RoleRM> = response.data;
      if (data.data !== null) {
        const roles = data.data.items.map((it) => this.dataMapper.map(it))
        return new Result.Success({
          items: roles,
          totalItemCount: data.data.total_item_count,
        })
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }


  updateRole = async (ro: UpdateRoleRequestObject) => {
    const response = await this.patch<APIResponseModel<RoleRM>>('userRole', ro)
    if (response instanceof Result.Success) {
      const data: APIResponseModel<RoleRM> = response.data
      if (data.data !== null) {
        const role = this.dataMapper.map(data.data.items[0])
        return new Result.Success(role)
      } else {
        return new Result.Error(0, 'Data is null')
      }
    } else {
      return response
    }
  }
}

export interface GetRolesRequestObject {
  id?: string
  name?: string
  level_greater_than?: number
}

export interface UpdateRoleRequestObject {
  id: string
  name: string
  level: number
  permissions: string[]
  backend_permissions: string[]
}
