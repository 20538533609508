import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './MrdNotification.css';
import LocalStorage from '../../../lib/LocalStorage';
import config from '../../../common/repository/config';
import Button from '../../elements/Button';
import { Table } from 'semantic-ui-react';

interface ApiResponse {
  success: boolean;
  message: string | null;
  data: {
    total_item_count: number;
    items: Notification[];
  };
}
interface Notification {
  _id: string;
  for: string;
  type: string;
  title: string;
  message: string;
  receiver: string[];
  deleted: boolean;
  history_id: string | null;
  type_id: string;
  sender: string;
  readBy: string[];
  createdAt: string;
  updatedAt: string;
  metadata: {
    case_category: string;
    case_type: string;
    claim_number: string;
    insured_name: string;
    hospital: {
      id: string;
      name: string;
      phone: string | null;
      email: string | null;
    };
    tpa: {
      insurance_company: string;
      tpa: string;
    };
    insurance_company: string;
    insured_location: {
      state: string;
      district: string;
    };
    closed_on: string;
    inward_date_time: string;
    remainders: any[];
  };
}

const ListFinishedInvestigation: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] = useState<'success' | 'error' | null>(null);
  const history = useHistory();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = LocalStorage.get(LocalStorage.KEY_AUTH_TOKEN);
        if (!token) {
          setToastMessage('Authentication token is missing.');
          setToastType('error');
          return;
        }

        const response = await fetch(`${config.apiBaseURL}/notification/listFinishedInvestigation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });

        if (!response.ok) throw new Error('Failed to fetch finished investigation notifications');

        const data: ApiResponse = await response.json();
        if (data.success && data.data?.items) {
          setNotifications(data.data.items);
        } else {
          setToastMessage('No notifications found.');
        }
      } catch (error) {
        setToastMessage('Error fetching finished investigation notifications.');
        setToastType('error');
        console.error('Error fetching finished investigation notifications:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    if (toastMessage) {
      setToastMessage(null);
      setToastType(null);
    }
  }, [toastMessage, toastType]);

  const calculateTAT = (createdAt: string): string => {
    const createdDate = new Date(createdAt);  
    const currentDate = new Date();


    const diffMs = currentDate.getTime() - createdDate.getTime();
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    if (diffDays > 0) {
      return `${diffDays}d ${diffHours}h`;
    } else if (diffHours > 0) {
      return `${diffHours}h ${diffMinutes}m`;
    } else {
      return `${diffMinutes}m`;
    }
};


  if (loading) {
    return <div>Loading...</div>;
  }

  const handleCaseView = (notification: Notification, event: React.MouseEvent) => {
    event.preventDefault();
    const caseId = notification.type_id;
    if (caseId) {
      history.push(`/case/view/${caseId}`);
    } else {
      console.error('Case ID is missing');
    }
  };

  return (
    <div className="mrd-notification">
      <h2>All Finished Case From App Listing</h2>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Reminder</Table.HeaderCell>
            <Table.HeaderCell>Case Category</Table.HeaderCell>
            <Table.HeaderCell>Case Type</Table.HeaderCell>
            <Table.HeaderCell>Running TAT</Table.HeaderCell>
            <Table.HeaderCell>Re-investigation TAT</Table.HeaderCell>
            <Table.HeaderCell>Claim Number</Table.HeaderCell>
            <Table.HeaderCell>TPA/Company</Table.HeaderCell>
            <Table.HeaderCell>Insured Name</Table.HeaderCell>
            <Table.HeaderCell>Insured District</Table.HeaderCell>
            <Table.HeaderCell>Hospital</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {notifications.length === 0 ? (
            <Table.Row>
              <Table.Cell colSpan="11" textAlign="center">
                No finished investigations found.
              </Table.Cell>
            </Table.Row>
          ) : (
            notifications.map((notification) => {
              const metadata = notification.metadata || {};
              const caseCategory = metadata.case_category || 'N/A';
              const caseType = metadata.case_type || 'N/A';
              const claimNumber = metadata.claim_number || 'N/A';
              const hospitalName = metadata.hospital?.name || 'N/A';
              const insuredName = metadata.insured_name || 'N/A';
              const insuredLocation = metadata.insured_location?.district || 'N/A';
              const remainders = metadata.remainders || 'N/A';
              const inwardDateTime = metadata.inward_date_time || 'N/A';
              const tpaCompany = metadata.tpa
                ? `${metadata.tpa.insurance_company || ''} - ${metadata.tpa.tpa || ''}`
                : 'Not Available';
              const closedOn = metadata.closed_on || 'N/A';
              const runningTAT = calculateTAT(notification.createdAt);

              return (
                <Table.Row key={notification._id}>
                  <Table.Cell>{remainders}</Table.Cell>
                  <Table.Cell>{caseCategory}</Table.Cell>
                  <Table.Cell>{caseType}</Table.Cell>
                  <Table.Cell>{runningTAT}</Table.Cell>
                  <Table.Cell>{closedOn}</Table.Cell>
                  <Table.Cell>{claimNumber}</Table.Cell>
                  <Table.Cell>{tpaCompany}</Table.Cell>
                  <Table.Cell>{insuredName}</Table.Cell>
                  <Table.Cell>{insuredLocation}</Table.Cell>
                  <Table.Cell>{hospitalName}</Table.Cell>
                  <Table.Cell>
                    <Button
                      style={{ backgroundColor: 'rgb(222, 255, 139)' }}
                      onClick={(event) => handleCaseView(notification, event)}
                    >
                      <i className="eye icon"></i> Open
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

export default ListFinishedInvestigation;
